<template>
  <mf-loading-dialog :loading="$apollo.loading">
    <base-page-layout title="Cadastro">
      <mf-card-container title="Inserir novas campanhas" description="Importar .csv com as campanhas Mercapromo">
        <template #headers>
          <mf-button label="Ajuda" icon="help" icon-position="right" color="primary" @click="goToManual" />
        </template>

        <div class="mt-4">
          <vertical-step class="mb-4" :number="1" text="Selecione a indústria" />
          <div style="margin-left: 14px; width: 366px">
            <select-industry ref="industrySelection" v-model="industry" required />
          </div>

          <vertical-step class="mb-4" :number="2" text="Selecione os termos de aceite" />
          <div class="my-4" style="margin-left: 14px;">
            <import-md @markdown-converted="updateMarkdown" @clear="clearMarkdown" />
            <terms-dialog v-show="convertedMarkdown" class="mt-4" :terms="convertedMarkdown" />
          </div>

          <vertical-step class="mb-4" :number="3" text="Selecione o tipo da campanha" />
          <div class="pb-4 d-flex align-center" style="margin-left: 14px; width: 542px; gap: 14px;">
            <v-select
              v-model="select"
              :items="items"
              item-text="name"
              item-value="value"
              hide-details
              return-object
              label="Tipo da campanha"
              outlined
              :menu-props="{ offsetY: true }"
              @change="clear"
            />
            <mf-button label="Acessar template" icon="open_in_new" text icon-position="right" color="primary" @click="goToTemplate" />
          </div>

          <vertical-step class="mb-4" :number="4" text="Selecione a planilha .csv" />
          <div class="my-4" style="margin-left: 14px;">
            <import-csv :disabled="!select" @done="update" @clear="clear" />
            <span>{{ sheetFileName }}</span>
          </div>

          <error-alert :errors="templateErrors.invalidNumberColumns" />
          <error-alert :errors="templateErrors.notFoundHeaders" />
          <error-alert :errors="templateErrors.invalidHeaders" />
          <error-alert :errors="campaignErrors.missingInfo" />
          <error-alert :errors="campaignErrors.invalidTypes" />
          <error-alert :errors="businessErrors" />

          <vertical-step v-if="select.value === 'digitalVoucher'" class="mb-4 mt-8" :number="4" text="Associe os vouchers às campanhas" />
          <vertical-step v-else class="mb-4 mt-8" :number="5" text="Confira algumas das campanhas importadas" />

          <v-data-table
            v-if="select.value === 'digitalVoucher'"
            :headers="headers"
            :items="sheet"
            :items-per-page="10"
            class="elevation-0"
            :loading="loading"
            :expanded.sync="expanded"
            item-key="details_id"
            show-expand
          >
            <template v-slot:item.name="{ item }" class="truncate-name">
              <tooltip align="top" fit-content :message="item.name">
                {{ formatCampaignTitle(item.name) | truncate({ length: 40 }) }}
              </tooltip>
            </template>

            <template v-slot:item.start_date="{ item }">
              {{ item.start_date | date }}
            </template>

            <template v-slot:item.end_date="{ item }">
              {{ item.end_date | date }}
            </template>

            <template v-slot:item.dynamic_rule[0].voucher_provider="{ item }">
              {{ item.dynamic_rule[0].voucher_provider ? item.dynamic_rule[0].voucher_provider : '-' }}
            </template>

            <template v-slot:item.actions="{ item }">
              <div v-if="voucherHasNoProvider(item)" class="d-flex">
                <import-csv small @done="updateVouchers($event, item)" @clear="clearVouchers(item)" />
              </div>
              <div v-else-if="isMinuCampaign(item)">
                <span>{{ item.dynamic_rule[0].minu_prize | truncate({ length: 30 }) }}</span>
              </div>
              <span v-else>Não é necessário</span>
            </template>

            <template v-slot:item.file_name="{ item }">
              <div v-if="voucherHasNoProvider(item)" class="d-flex">
                <v-icon v-if="item.equal_alert" class="mr-2" small color="#FFC107">
                  warning
                </v-icon>
                <v-icon v-else-if="item.vouchers && item.vouchers.length > 0" class="mr-2" small color="green">
                  check_circle
                </v-icon>
                <v-icon v-else class="mr-2" small color="red">
                  error
                </v-icon>
                <span>{{ item.file_name }}</span>
              </div>
              <div v-else-if="isMinuCampaign(item) && item.dynamic_rule[0].voucher_type.toUpperCase() === 'IFOOD'">
                <v-icon v-if="validIFoodMinuPrize(item)" class="mr-2" small color="green">
                  check_circle
                </v-icon>
                <div v-else class="d-flex">
                  <v-icon class="mr-2" small color="#FFC107">
                    warning
                  </v-icon>
                  <span>minu_prize inválido</span>
                </div>
              </div>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" :style="{ boxShadow: 'none !important', backgroundColor: expandableTableBackground }">
                <vue-json-pretty :deep="1" :data="item" highlight-mouseover-node show-line />
              </td>
            </template>
          </v-data-table>
          <div v-else style="max-height: 300px; overflow: auto">
            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
            <vue-json-pretty v-else :deep="1" :data="sheetPreviewJson" highlight-mouseover-node show-line />
          </div>
        </div>
      </mf-card-container>

      <div class="d-flex flex-row align-center justify-end mt-2" style="gap: 8px;">
        <mf-action-buttons
          :primary-button="{
            text: 'Inserir',
            action: save,
            isVisible: true,
            isDisabled: $apollo.loading || this.sheet.length <= 0 || hasErrors,
            isLoading: $apollo.loading || loading
          }"
          :cancel-button="{
            text: 'Cancelar',
            action: () => $router.push('/mercapromo'),
            isVisible: true,
            isDisabled: false,
            isLoading: false
          }"
        />
      </div>
    </base-page-layout>
  </mf-loading-dialog>
</template>

<script>
import { MUTATION_INSERT_MERCAPROMO } from '@/modules/industry/graphql'
import Papa from 'papaparse'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import TermsDialog from '@/modules/industry/mercapromo/views/components/TermsDialog.vue'
import { validateTemplate, convertAndValidateCampaigns } from '@/modules/industry/helpers/templateValidation.js'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    ImportCsv: () => import('@/modules/industry/components/ImportCsv.vue'),
    ImportMd: () => import('@/modules/industry/components/ImportMd.vue'),
    VerticalStep: () => import('@/modules/industry/components/VerticalStep.vue'),
    ErrorAlert: () => import('@/modules/industry/mercapromo/views/components/ErrorAlert.vue'),
    Tooltip: () => import('@/components/base/Tooltip.vue'),
    SelectIndustry: () => import('@/modules/industry/components/SelectIndustry.vue'),
    VueJsonPretty,
    TermsDialog
  },
  data: () => ({
    loading: false,
    validatingMinuPrizes: false,
    items: [
      {
        name: 'Leve & Pague',
        value: 'takepay'
      },
      {
        name: 'Nextbuy Cashback',
        value: 'nextbuycashback'
      },
      {
        name: 'Voucher Digital',
        value: 'digitalVoucher'
      },
      {
        name: 'Cross Sell',
        value: 'crosssell'
      },
      {
        name: 'Desconto Absoluto',
        value: 'general'
      },
      {
        name: 'Desconto Percentual',
        value: 'percentage'
      }
    ],
    select: {
      name: 'Leve & Pague',
      value: 'takepay'
    },
    sheet: [],
    sheetFileName: '',
    sheetPreview: [],
    sheetPreviewJson: '',
    convertedMarkdown: '',
    expanded: [],
    industry: {},
    commonTemplateFields: [
      { name: 'id_external', type: 'string', required: false },
      { name: 'db_name', type: 'string', required: false },
      { name: 'name', type: 'string', required: false },
      { name: 'units', type: 'string', required: false },
      { name: 'unit_tags', type: 'string', required: false },
      { name: 'start_date', type: 'date', required: false },
      { name: 'end_date', type: 'date', required: false },
      { name: 'proposal_deadline', type: 'date', required: false },
      { name: 'sms', type: 'string', required: false },
      { name: 'ean', type: 'string', required: false },
      { name: 'product_name', type: 'string', required: false },
      { name: 'label_pdv', type: 'string', required: true, maxLength: 100 },
      { name: 'products_group', type: 'boolean', required: false },
      { name: 'channel_platform', type: 'boolean', required: false },
      { name: 'channel_ecommerce', type: 'boolean', required: false },
      { name: 'is_featured', type: 'boolean', required: false },
      { name: 'whatsapp_template', type: 'string', required: false },
      { name: 'whatsapp_placeholders', type: 'string', required: false },
      { name: 'whatsapp_number', type: 'string', required: false }
    ],
    specificTemplateFields: {
      takepay: [
        { name: 'take', type: 'float', required: true },
        { name: 'pay', type: 'float', required: true },
        { name: 'maximum_per_client', type: 'int', required: true }
      ],
      nextbuycashback: [
        { name: 'cashback_absolute_discount', type: 'float', required: true },
        { name: 'minimum_per_client', type: 'int', required: true },
        { name: 'max_coupons', type: 'int', required: true },
        { name: 'cashback_expiration_date', type: 'date', required: true }
      ],
      crosssell: [
        { name: 'discount_type', type: 'fixed', required: false, validEntries: ['relative'] },
        { name: 'discount', type: 'float', required: false },
        { name: 'trigger_amount', type: 'float', required: false },
        { name: 'target_ean', type: 'string', required: false },
        { name: 'maximum_per_client', type: 'int', required: false },
        { name: 'products_group_target', type: 'string', required: false },
        { name: 'product_name_target', type: 'string', required: false }
      ],
      digitalVoucher: [
        { name: 'voucher_value', type: 'int', required: true },
        { name: 'minimum_per_client', type: 'int', required: true },
        { name: 'max_triggers', type: 'int', required: true },
        { name: 'voucher_provider', type: 'string', required: false },
        { name: 'voucher_type', type: 'string', required: false },
        { name: 'hotsite_expiration_date', type: 'date', required: true },
        { name: 'minu_prize', type: 'string', required: false }
      ],
      general: [
        { name: 'price_1', type: 'float', required: true },
        { name: 'price_2', type: 'float', required: true },
        { name: 'minimum_per_client', type: 'int', required: true },
        { name: 'maximum_per_client', type: 'int', required: true }
      ],
      percentage: [
        { name: 'percentage_discount', type: 'int', required: true },
        { name: 'minimum_per_client', type: 'int', required: true },
        { name: 'maximum_per_client', type: 'int', required: true }
      ]
    },
    templateErrors: {
      invalidNumberColumns: [],
      notFoundHeaders: [],
      invalidHeaders: []
    },
    campaignErrors: {
      missingInfo: [],
      invalidTypes: []
    },
    businessErrors: []
  }),
  computed: {
    headers() {
      return [
        {
          text: 'ID Externo',
          value: 'id_external',
          sortable: false
        },
        {
          text: 'DB Name',
          value: 'db_name',
          sortable: true
        },
        {
          text: 'Nome',
          value: 'name',
          width: '30%',
          sortable: true
        },
        {
          text: 'Data início',
          value: 'start_date',
          sortable: false
        },
        {
          text: 'Data fim',
          value: 'end_date',
          sortable: false
        },
        {
          text: 'Provedor',
          value: 'dynamic_rule[0].voucher_provider',
          sortable: true
        },
        {
          text: 'Tipo',
          value: 'dynamic_rule[0].voucher_type',
          sortable: true
        },
        {
          text: 'Vouchers',
          value: 'actions',
          sortable: false
        },
        {
          text: '',
          value: 'file_name',
          sortable: false
        },
        { text: '', value: 'data-table-expand' }
      ]
    },

    unassociatedVouchers() {
      if (this.select.value === 'digitalVoucher') {
        return this.sheet.some(campaign => campaign.vouchers.length <= 0 && this.voucherHasNoProvider(campaign))
      }
      return false
    },

    checkForEqualAlerts() {
      if (this.select.value === 'digitalVoucher') {
        return this.sheet.some(campaign => campaign.equal_alert)
      }
      return false
    },

    hasErrors() {
      return (
        Object.values(this.templateErrors).some(arrayError => arrayError.length > 0) ||
        Object.values(this.campaignErrors).some(arrayError => arrayError.length > 0) ||
        this.businessErrors.length > 0
      )
    },

    darkMode() {
      return localStorage.getItem('dark_theme') === 'true'
    },

    expandableTableBackground() {
      return this.darkMode ? 'black' : '#f7f7f7'
    }
  },
  methods: {
    clear() {
      this.sheet = []
      this.sheetPreview = []
      this.sheetFileName = ''
      this.sheetPreviewJson = ''
      this.templateErrors = {
        invalidNumberColumns: [],
        notFoundHeaders: [],
        invalidHeaders: []
      }
      this.campaignErrors = {
        invalidTypes: [],
        missingInfo: []
      }
      this.businessErrors = []
    },

    updateMarkdown(markdown) {
      this.convertedMarkdown = markdown
    },

    clearMarkdown() {
      this.convertedMarkdown = ''
    },

    voucherHasNoProvider(campaign) {
      return campaign?.dynamic_rule[0]?.voucher_provider?.length === 0
    },

    isMinuCampaign(campaign) {
      return campaign?.dynamic_rule[0]?.voucher_provider === 'MINU'
    },

    goToManual() {
      window.open('https://mercafacil.getoutline.com/doc/manual-backoffice-mercapromo-jWBJcN1XoP', '_blank')
    },

    goToTemplate() {
      let link = ''
      switch (this.select.value) {
        case 'takepay':
          link = 'https://docs.google.com/spreadsheets/d/1Nxgn05U9CegxgZS9CMQ85L5-ae9hQwX0QCcmWOScUjc/edit?usp=sharing'
          break
        case 'nextbuycashback':
          link = 'https://docs.google.com/spreadsheets/d/1oVTTBAlZ0uxl54OX94zx0csz-ax15Emlj1cyVKzL_qw/edit?usp=sharing'
          break
        case 'crosssell':
          link = 'https://docs.google.com/spreadsheets/d/1zEFM8W8RuIkikuvRinc1BJ4Ts9cH2z4kpHF9zjJuqLw/edit?usp=sharing'
          break
        case 'digitalVoucher':
          link = 'https://docs.google.com/spreadsheets/d/16d71mGS8wEmxwOkWxCP3berU9wMrFNDCwZasInTOnyQ/edit?usp=sharing'
          break
        case 'general':
          link = 'https://docs.google.com/spreadsheets/d/1a-mhUNiJxukTqlUr8bhxtw4Xi2o6UQ3G8g1aHIPEJME/edit?usp=sharing'
          break
        case 'percentage':
          link = 'https://docs.google.com/spreadsheets/d/1wFvQny9gqzl_1pOXv_h6vGyh56Ise6w-eDcOcewnQQ4/edit?usp=sharing'
          break
      }
      window.open(link, '_blank')
    },

    async save() {
      this.loading = true
      this.$refs.industrySelection.$v.$touch()
      if (this.unassociatedVouchers) {
        this.$snackbar({ message: 'Há campanhas sem vouchers associados', snackbarColor: 'error' })
        this.loading = false
        return
      }

      if (!this.convertedMarkdown) {
        this.$snackbar({ message: 'Nenhum termo de aceite foi adicionado', snackbarColor: 'error' })
        this.loading = false
        return
      }

      if (!this.$refs.industrySelection.valid()) {
        this.loading = false
        return
      }

      if (this.checkForEqualAlerts) {
        const confirmation = await this.$confirm({
          title: 'Inserir campanhas com vouchers iguais?',
          message: 'Existem campanhas com vouchers iguais, inserir mesmo assim?',
          confirmText: 'Inserir',
          cancelText: 'Cancelar'
        })
          .then(() => true)
          .catch(() => false)
        if (!confirmation) {
          this.loading = false
          return
        }
      }

      this.removeUselessProperties()
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_INSERT_MERCAPROMO,
          variables: {
            input: {
              industry_id: this.industry._id,
              offers: this.sheet,
              terms: this.convertedMarkdown
            }
          },
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id'),
              accountId: this.$route.params.id
            }
          }
        })
        this.$alert({
          alert_message: 'Mercapromo criado com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$router.push(`/mercapromo`)
      } catch (error) {
        this.$alert({
          alert_message: 'Erro ao enviar campanhas',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle',
          action: () => {
            const { errors } = error.networkError.result
            this.$errorDialog({
              title: errors.length > 1 ? 'Detalhes dos erros' : 'Detalhes do erro',
              errors: errors
            })
          },
          action_label: 'Ver detalhes'
        })

        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          const graphQLError = error.graphQLErrors[0]
          const details = graphQLError.message || 'Detalhes do erro indisponíveis'
          console.error(details)
        }
      } finally {
        this.loading = false
      }
    },

    validIFoodMinuPrize(item) {
      const validMinuPrizes = ['ifood-credito-r10', 'ifood-credito-r15', 'ifood-credito-r20']
      return validMinuPrizes.includes(item.dynamic_rule[0].minu_prize)
    },

    removeUselessProperties() {
      if (this.select.value === 'digitalVoucher') {
        this.sheet.forEach(campaign => {
          delete campaign.equal_alert
          delete campaign.file_name
          delete campaign.details_id
        })
      }
    },

    isFirstLineValid(campaign) {
      if (campaign.name) {
        return true
      }
      return false
    },

    update(sheet) {
      this.loading = true
      this.clear()
      this.sheetFileName = sheet.fileName
      let validatingFields = this.commonTemplateFields.concat(this.specificTemplateFields[this.select.value])
      try {
        const parsed = Papa.parse(sheet.readed, { header: true, skipEmptyLines: true, dynamicTyping: true })

        if (!this.isFirstLineValid(parsed.data[0])) {
          this.campaignErrors.missingInfo.push({
            line: 2,
            error: 'Primeira linha precisa ser uma campanha'
          })
          return
        }

        this.templateErrors = validateTemplate(parsed, validatingFields)
        if (this.hasErrors) return

        const { errors, converted } = convertAndValidateCampaigns(parsed, validatingFields)
        this.campaignErrors = errors
        if (this.hasErrors) return

        switch (this.select.value) {
          case 'takepay':
            this.convertToTakepay(converted)
            break
          case 'nextbuycashback':
            this.convertToNextbuycashback(converted)
            break
          case 'crosssell':
            this.convertToCrossSell(converted)
            break
          case 'digitalVoucher':
            this.convertToDigitalVoucher(converted)
            break
          case 'general':
            this.convertToGeneral(converted)
            break
          case 'percentage':
            this.convertToPercentage(converted)
            break
        }
        this.sheetPreviewJson = JSON.parse(JSON.stringify(this.sheet.slice(0, 100)))
        this.$alert({
          alert_message: `${this.sheet.length} registros importados com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        console.error(err)
        this.$alert({
          alert_message: 'Nenhum registro importado',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      } finally {
        this.loading = false
      }
    },

    removeWhitespace(value) {
      if (value && typeof value === 'string') {
        return value.replaceAll(', ', ',')
      }
      return value
    },

    addWhatsapp(campaign, index) {
      const { whatsapp_template, whatsapp_number, whatsapp_placeholders } = campaign
      const hasWhatsapp = whatsapp_template || whatsapp_number || whatsapp_placeholders
      const hasValidWhatsappFields = whatsapp_template && whatsapp_number && whatsapp_placeholders

      if (!hasWhatsapp) {
        return {}
      }

      if (hasValidWhatsappFields) {
        return {
          whatsapp_configuration: {
            template: campaign.whatsapp_template,
            placeholders: campaign.whatsapp_placeholders,
            phone: campaign.whatsapp_number
          }
        }
      }

      this.businessErrors.push({
        line: index + 2,
        error: `Cadastro de envio de Whatsapp inválido.
              Verifique os campos de whatsapp_template, whatsapp_number e whatsapp_placeholders.`
      })
      return {}
    },

    commonConvert(campaign, index) {
      return {
        id_external: campaign?.id_external,
        db_name: campaign.db_name,
        name: campaign.name,
        units: campaign.units ? campaign.units : '[]',
        unit_tags: this.removeWhitespace(campaign.unit_tags),
        start_date: campaign.start_date,
        end_date: campaign.end_date,
        sms: campaign.sms,
        proposal_deadline: campaign.proposal_deadline,
        channel_configuration: {
          platform: !campaign.channel_platform || campaign.channel_platform?.toLowerCase() === 'sim',
          ecommerce: campaign.channel_ecommerce?.toLowerCase() === 'sim'
        },
        is_featured: campaign.is_featured?.toLowerCase() === 'sim',
        ...this.addWhatsapp(campaign, index)
      }
    },

    convertToTakepay(campaigns) {
      const takepayCampaigns = []
      campaigns.forEach((campaign, index) => {
        const takepay = []
        takepay.push({
          ean: campaign?.ean,
          take: campaign.take,
          pay: campaign.pay,
          maximum_per_client: campaign.maximum_per_client,
          product_name: campaign.product_name,
          label_pdv: campaign.label_pdv
        })

        if (!campaign.name && campaign.ean) {
          takepayCampaigns[takepayCampaigns.length - 1].takepay.push(...takepay)
        } else if (campaign.name) {
          const commonProperties = this.commonConvert(campaign, index)
          takepayCampaigns.push({
            ...commonProperties,
            products_group: campaign.products_group?.toLowerCase() === 'sim',
            offer_type: 'takepay',
            takepay
          })
        } else {
          this.campaignErrors.missingInfo.push({
            line: index + 2,
            error: 'Não importado pois falta EAN'
          })
        }
      })

      this.sheet = takepayCampaigns
    },

    convertToNextbuycashback(campaigns) {
      const cashbackCampaigns = []
      campaigns.forEach((campaign, index) => {
        const nextbuycashback = []
        nextbuycashback.push({
          ean: campaign?.ean,
          max_coupons: campaign.max_coupons,
          minimum_per_client: campaign.minimum_per_client,
          cashback_absolute_discount: campaign.cashback_absolute_discount,
          cashback_expiration_date: campaign.cashback_expiration_date,
          product_name: campaign.product_name,
          label_pdv: campaign.label_pdv
        })

        if (!campaign.name && campaign.ean) {
          cashbackCampaigns[cashbackCampaigns.length - 1].nextbuycashback.push(...nextbuycashback)
        } else if (campaign.name) {
          const commonProperties = this.commonConvert(campaign, index)
          cashbackCampaigns.push({
            ...commonProperties,
            products_group: campaign.products_group?.toLowerCase() === 'sim',
            offer_type: 'nextbuycashback',
            nextbuycashback
          })
        } else {
          this.campaignErrors.missingInfo.push({
            line: index + 2,
            error: 'Não importado pois falta informações'
          })
        }
      })

      this.sheet = cashbackCampaigns
    },

    convertToCrossSell(campaigns) {
      const crossSellCampaign = []
      campaigns.forEach((campaign, index) => {
        if (!campaign.name && (campaign.ean || campaign.target_ean)) {
          if (campaign.ean) {
            crossSellCampaign[crossSellCampaign.length - 1].cross_sell.products.push({
              ean: campaign?.ean,
              name: campaign.product_name
            })
          }
          if (campaign.target_ean) {
            crossSellCampaign[crossSellCampaign.length - 1].cross_sell.target_products.push({
              ean: campaign?.target_ean,
              name: campaign.product_name_target
            })
          }
        } else if (campaign.name) {
          const commonProperties = this.commonConvert(campaign, index)
          crossSellCampaign.push({
            ...commonProperties,
            products_group_target: campaign.products_group_target?.toLowerCase() === 'sim',
            offer_type: 'cross_sell',
            products_group: campaign.products_group?.toLowerCase() === 'sim',
            cross_sell: {
              products: [
                {
                  ean: campaign?.ean,
                  name: campaign.product_name
                }
              ],
              trigger_amount: campaign.trigger_amount,
              target_products: [
                {
                  ean: campaign?.target_ean,
                  name: campaign.product_name_target
                }
              ],
              discount: campaign.discount,
              discount_type: campaign.discount_type,
              maximum_per_client: campaign.maximum_per_client,
              label_pdv: campaign.label_pdv
            }
          })
        } else {
          this.campaignErrors.missingInfo.push({
            line: index + 2,
            error: 'Não importado pois falta informações'
          })
        }
      })

      this.sheet = crossSellCampaign
    },

    convertToGeneral(campaigns) {
      const generalCampaign = []
      campaigns.forEach((campaign, index) => {
        const general = []
        general.push({
          ean: campaign?.ean,
          price_1: campaign.price_1,
          price_2: campaign.price_2,
          minimum_per_client: campaign.minimum_per_client,
          maximum_per_client: campaign.maximum_per_client,
          product_name: campaign.product_name,
          label_pdv: campaign.label_pdv
        })

        if (!campaign.name && campaign.ean) {
          generalCampaign[generalCampaign.length - 1].general.push(...general)
        } else if (campaign.name) {
          const commonProperties = this.commonConvert(campaign, index)
          generalCampaign.push({
            ...commonProperties,
            products_group: campaign.products_group?.toLowerCase() === 'sim',
            offer_type: 'general',
            general
          })
        } else {
          this.campaignErrors.missingInfo.push({
            line: index + 2,
            error: 'Não importado pois falta informações'
          })
        }
      })

      this.sheet = generalCampaign
    },

    convertToPercentage(campaigns) {
      const percentageCampaign = []
      campaigns.forEach((campaign, index) => {
        const percentage = []
        percentage.push({
          ean: campaign?.ean,
          percentage_discount: campaign.percentage_discount,
          minimum_per_client: campaign.minimum_per_client,
          maximum_per_client: campaign.maximum_per_client,
          product_name: campaign.product_name,
          label_pdv: campaign.label_pdv
        })

        if (!campaign.name && campaign.ean) {
          percentageCampaign[percentageCampaign.length - 1].percentage.push(...percentage)
        } else if (campaign.name) {
          const commonProperties = this.commonConvert(campaign, index)
          percentageCampaign.push({
            ...commonProperties,
            products_group: campaign.products_group?.toLowerCase() === 'sim',
            offer_type: 'percentage',
            percentage
          })
        } else {
          this.campaignErrors.missingInfo.push({
            line: index + 2,
            error: 'Não importado pois falta informações'
          })
        }
      })

      this.sheet = percentageCampaign
    },

    convertToDigitalVoucher(campaigns) {
      const digitalVoucherCampaign = []
      campaigns.forEach((campaign, index) => {
        const dynamic_rule = []
        dynamic_rule.push({
          ean: campaign?.ean,
          voucher_value: campaign.voucher_value,
          minimum_per_client: campaign.minimum_per_client,
          max_triggers: campaign.max_triggers,
          voucher_provider: campaign.voucher_provider ? campaign.voucher_provider?.toUpperCase() : '',
          voucher_type: campaign.voucher_type?.toUpperCase(),
          hotsite_expiration_date: campaign.hotsite_expiration_date,
          minu_prize: campaign.minu_prize,
          product_name: campaign.product_name,
          label_pdv: campaign.label_pdv
        })

        if (!campaign.name && campaign.ean) {
          digitalVoucherCampaign[digitalVoucherCampaign.length - 1].dynamic_rule.push(...dynamic_rule)
        } else if (campaign.name) {
          const commonProperties = this.commonConvert(campaign, index)
          digitalVoucherCampaign.push({
            ...commonProperties,
            equal_alert: false,
            file_name: 'Sem vouchers',
            vouchers: [],
            details_id: index,
            offer_type: 'dynamic_rule',
            products_group: campaign.products_group?.toLowerCase() === 'sim',
            dynamic_rule
          })
        } else {
          this.campaignErrors.missingInfo.push({
            line: index + 2,
            error: 'Não importado pois falta informações'
          })
        }
      })

      this.sheet = digitalVoucherCampaign
    },

    updateVouchers(sheet, item) {
      this.clearVouchers(item)
      const parsed = Papa.parse(sheet.readed, { header: true, skipEmptyLines: true, dynamicTyping: true })
      if (!this.validVoucherTemplate(parsed, ['Voucher Link'])) return
      parsed.data.forEach(voucher => {
        item.vouchers.push(voucher['Voucher Link'])
      })
      item.file_name = sheet.fileName
      this.checkForEqualVouchers()
    },

    checkForEqualVouchers() {
      for (let i = 0; i < this.sheet.length; i++) {
        const campaign1 = this.sheet[i]
        campaign1.equal_alert = false

        for (let j = 0; j < this.sheet.length; j++) {
          if (i !== j) {
            const campaign2 = this.sheet[j]

            if (campaign1.vouchers.some(voucher => campaign2.vouchers.includes(voucher))) {
              campaign1.equal_alert = true
              break
            }
          }
        }
      }
    },

    clearVouchers(item) {
      item.vouchers = []
      item.equal_alert = false
      item.file_name = 'Sem vouchers'
      this.checkForEqualVouchers()
    },

    validVoucherTemplate(parsedSheet, requiredHeaders) {
      const headers = parsedSheet.meta.fields
      for (const header of requiredHeaders) {
        if (!headers.includes(header)) {
          this.$snackbar({ message: 'Arquivo .csv inválido para esse tipo de voucher', snackbarColor: 'error' })
          return false
        }
      }
      return true
    },

    formatCampaignTitle(title) {
      let newTitle = title.replace('Campanha Mercapromo', ' ')
      return newTitle
    }
  }
}
</script>
